import React from 'react';
import Link from 'src/components/omnicasa-link';
import * as COMMON from 'root/_common';
import { withTranslation } from 'react-i18next';
import { findParamQueryString } from 'root/_ultility';

interface IFeedBackProps {
  t?: any;
  i18n?: any;
  location?: any;
}

interface IFeedBackState {
  success: string;
  ContactName: string;
  id: number;
}

class FeedBackComponent extends React.Component<IFeedBackProps, IFeedBackState> {
  constructor(props: IFeedBackProps) {
    super(props);
    this.state = {
      success: '', // 0 = false, 1 = true
      ContactName: '',
      id: 0
    };
  }

  componentDidMount() {
    const success = findParamQueryString('success');
    const ContactName = findParamQueryString('f');
    const id = findParamQueryString('id');
    // @ts-ignore
    this.setState({ success, ContactName, id });
  }

  getFeedBackTitle(ContactName: string) {
    switch (ContactName) {
      case 'CONTACT_CONSTRUCTION': return 'CONTACT_CONSTRUCTION_FEEDBACK_TITLE';
      case 'CONTACT_ME': return 'CONTACT_ME_FEEDBACK_TITLE';
      case 'CONTACT_APPOINMENT': return 'CONTACT_ME_FEEDBACK_TITLE';
      case 'CONTACT_TESTIMONIAL': return 'CONTACT_TESTIMONIAL_TITLE';
      default: return '';
    }
  }
  getFeedBackMessage(ContactName: string, success: string) {
    switch (ContactName) {
      case 'CONTACT_DEMAND': return success === '1' ? 'CONTACT_DEMAND_FEEDBACK_SUCCESS' : 'CONTACT_DEMAND_FEEDBACK_ERROR';
      case 'CONTACT_ME': return success === '1' ? 'CONTACT_ME_FEEDBACK_SUCCESS' : 'CONTACT_ME_FEEDBACK_ERROR';
      case 'CONTACT_APPOINMENT': return success === '1' ? 'CONTACT_ME_FEEDBACK_SUCCESS' : 'CONTACT_ME_FEEDBACK_ERROR';
      case 'DEMAND_REGISTER': return success === '1' ? 'MAIL_THANK_TEXT' : 'MAIL_ERROR';
      case 'SEND_TO_FRIEND': return success === '1' ? 'SEND_TO_FRIEND_FEEDBACK_SUCCESS' : 'SEND_TO_FRIEND_FEEDBACK_ERROR';
      case 'SITE_CONTACT': return success === '1' ? 'SITE_CONTACT_FEEDBACK_SUCCESS' : 'SITE_CONTACT_FEEDBACK_ERROR';
      case 'SITE_CONTACT_SMALL': return success === '1' ? 'SITE_CONTACT_FEEDBACK_SUCCESS' : 'SITE_CONTACT_FEEDBACK_ERROR';
      case 'CONTACT_TESTIMONIAL': return success === '1' ? 'CONTACT_TESTIMONIAL_FEEDBACK_SUCCESS' : 'CONTACT_TESTIMONIAL_FEEDBACK_ERROR';
      default: return '';
    }
  }

  getReturnURL() {
    if (typeof window !== 'undefined') {
      // get Url back
      const Url = window.sessionStorage.getItem('URL');
      return !!Url ? Url : '/';
    } else {
      return '/';
    }
  }

  render() {
    const { t, location } = this.props;
    const successMessage =
      location.state && location.state.message
        ? location.state.message
          : t(this.getFeedBackMessage(this.state.ContactName.toUpperCase(), this.state.success));
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '75vh', flexDirection: 'column' }}>
        {this.state.ContactName === 'DEMAND_REGISTER' && <Link to={this.getReturnURL()}>{t('DEMAND_FEEDBACK_RETURN')}</Link>}
        <div className={this.state.success === '1' ? 'success_title' : 'error_title'}><h3>{t(this.getFeedBackTitle(this.state.ContactName.toUpperCase()))}</h3></div>
        <div className={this.state.success === '1' ? 'success_message' : 'error_message'}><p>{successMessage}</p></div>
        {this.state.id > 0 && <><br /><Link to={"/property/?id=" + this.state.id} className="btn btn-secondary">{t('BACK_TO_PROPERTY')}</Link></>}
      </div>
    );
  }
}

export default withTranslation(COMMON.TRANSLATION_DEFAULT)(FeedBackComponent);
