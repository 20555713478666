import React from 'react';
import Layout from '../components/layout';
import FeedBackForm from 'src/views/template-01/contact/feedback';

interface IFeedBackPageProps {
    pageContext: any;
    location?: any;
}

function FeedBackPage(props: IFeedBackPageProps) {
    const { pageContext, location } = props;
    return (
        <Layout
            config={pageContext.layout}
            isHomePage={pageContext.isHomePage}
        >
            <div className='container'>
                <FeedBackForm location={location} />
            </div>
        </Layout >
    );
}

export default FeedBackPage;
